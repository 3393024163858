/* eslint-disable import/no-cycle */

// Import libraries and polyfills
import Promise from "promise-polyfill";
import "whatwg-fetch";
import "focus-visible";
import "classlist-polyfill";
import { handle, enhance } from "@grrr/hansel";
import rafPolyfill from "./polyfills/request-animation-frame";

// Import functions that are executed on DOMready regardless of DOM
import { onDomReady } from "./modules/ready";
import { enhancer as scrollListener } from "./modules/scroll-listener";
import { enhancer as responsive } from "./modules/responsive";
import { default as disableHoverStylesOnScroll } from "./modules/disable-hover-styles-on-scroll";

// Import handlers
import { handler as classToggler } from "./modules/class-toggler";
import { handler as discoveryScrollerCardClick } from "./modules/discovery-scroller";
import { handler as foldoutItem } from "./modules/foldout-item";
import { handler as gtmClickHandler } from "./modules/gtm-event";
import { handler as remembranceModal } from "./modules/remembrance-modal";
import { closeHandler as closeRemembranceModal } from "./modules/remembrance-modal";
import { toggleHandler as siteNavToggler } from "./modules/site-nav";
import { handler as languageSwitch } from "./modules/language-switch";
import {
  close as lazyVideoEmbedClose,
  play as lazyVideoEmbedPlay,
} from "./modules/lazy-video-embed";
import { handler as showCookieDialog } from "./modules/cookie-consent";

// Import enhancers
import { enhancer as ajaxifyForm } from "./modules/ajaxify-form";
import { enhancer as conditionalFields } from "./modules/conditional-fields";
import { enhancer as cookieConsent } from "./modules/cookie-consent";
import { enhancer as discoveryScroller } from "./modules/discovery-scroller";
import { enhancer as formValidation } from "./modules/form-validation";
import { enhancer as frontPage } from "./modules/front-page";
import { enhancer as frontPageSiteListing } from "./modules/front-page-site-listing";
import { enhancer as gtmFormEnhancer } from "./modules/gtm-event";
import { enhancer as newsletterSignup } from "./modules/newsletter-signup";
import { enhancer as objectFit } from "./modules/object-fit";
import { enhancer as passwordProtectionForm } from "./modules/password-protection-form";
import { enhancer as remembrancePerson } from "./modules/remembrance-person";
import { enhancer as repositionPostLabel } from "./modules/reposition-post-label";
import { enhancer as responsiveVideo } from "./modules/responsive-video";
import { enhancer as siteNavEnhancer } from "./modules/site-nav";
import { enhancer as stickyCampaignBar } from "./modules/sticky-campaign-bar";

const executeOnReady = () => {
  disableHoverStylesOnScroll(); // Disable hover styles on scroll
  scrollListener(); // Initialise central scroll listener
  responsive(); // Set document width on resize and orientation change

  window.requestAnimationFrame = window.requestAnimationFrame || rafPolyfill;

  if (typeof window.Promise === "undefined") {
    window.Promise = Promise;
  }
};

export const ENHANCERS = {
  ajaxifyForm,
  conditionalFields,
  cookieConsent,
  discoveryScroller,
  formValidation,
  frontPage,
  frontPageSiteListing,
  gtmFormEnhancer,
  newsletterSignup,
  objectFit,
  passwordProtectionForm,
  remembrancePerson,
  repositionPostLabel,
  responsiveVideo,
  siteNavEnhancer,
  stickyCampaignBar,
};

export const HANDLERS = {
  classToggler,
  discoveryScrollerCardClick,
  foldoutItem,
  gtmClickHandler: {
    fn: gtmClickHandler,
    options: {
      allowModifierKeys: true,
    },
  },
  languageSwitch,
  lazyVideoEmbedClose,
  lazyVideoEmbedPlay,
  closeRemembranceModal,
  remembranceModal,
  siteNavToggler,
  showCookieDialog,
};

const main = () => {
  executeOnReady();
  enhance(document.documentElement, ENHANCERS);
  handle(document.documentElement, HANDLERS);
};

onDomReady(main);
