import Cookies from "js-cookie";
import CookieConsent from "@grrr/cookie-consent";
import { takeLast } from "@grrr/utils";
import { pushEvent } from "./gtm-event";

const updateHandler = (cookies) => {
  // Fire single GTM event with cookie preferences.
  const consentFeatures = cookies.reduce((acc, cookie) => {
    acc[cookie.id] = `${cookie.accepted}`;
    return acc;
  }, {});
  pushEvent({
    event: "cookieConsent",
    consentFeatures,
  });

  // Set actual cookies per type, used for fetch cross-subdomain cookie consent
  // via GTM (for example ticketing domain).
  cookies.forEach((cookie) => {
    const rootDomain = takeLast(2, window.location.hostname.split(".")).join(
      "."
    );
    Cookies.set(`cookieConsent_${cookie.id}`, `${cookie.accepted}`, {
      expires: 365,
      domain: rootDomain,
      secure: true,
    });
  });
};

export const enhancer = () => {
  if (!window.COOKIE_CONSENT_CONFIG) {
    return;
  }

  // An optional cookie might be present from the subdomains.
  // If so, settings in the cookie are leading, and we will manipulate
  // localStorage before initializing the CookieConsent module.
  const consentSettingsFromCookies =
    window.COOKIE_CONSENT_CONFIG.cookies.reduce((acc, cookieOption) => {
      const consentCookie = Cookies.get(`cookieConsent_${cookieOption.id}`);
      if (consentCookie === undefined) {
        return acc;
      }
      acc.push({
        id: cookieOption.id,
        accepted: consentCookie === "true",
      });
      return acc;
    }, []);

  if (consentSettingsFromCookies.length > 0 && window.localStorage) {
    localStorage.setItem(
      "cookie-consent-preferences",
      JSON.stringify(
        consentSettingsFromCookies.map((cookie) => ({
          id: cookie.id,
          accepted: cookie.accepted,
        }))
      )
    );
  }

  // Construct and initialize the module.
  const cookieConsent = CookieConsent(window.COOKIE_CONSENT_CONFIG);

  // Handle `update` events.
  cookieConsent.on("update", updateHandler);

  // Make the object globally available.
  window.CookieConsent = cookieConsent;
};

export const handler = (el, e) => {
  e.preventDefault();
  window.CookieConsent.showDialog();
};
